import { Pipe, PipeTransform } from '@angular/core';
import { PaperType } from '../enums/PaperType.enum';
import { StorageService } from '../services';

@Pipe({
    name: 'paperName',
})
export class PaperNamePipe implements PipeTransform {
    constructor(private storage: StorageService) {}
    transform(type: PaperType, args?: 'short' | string): string {
        const shortName = args === 'short';
        switch (type) {
            case PaperType.IZVID:
            case PaperType.SURVEY_CUSTOMER:
                return this.getPaperTitle(type, 'Izvid');
            case PaperType.FTH_POROCILO:
                return this.getPaperTitle(type, shortName ? 'FT poročilo' : 'Fizioterapevtsko poročilo');
            case PaperType.WHITE_REFERRAL:
                return 'Bela napotnica';
            case PaperType.HELLODOC_REFERRAL:
                return shortName ? 'HD napotnica' : 'Halo Doktor napotnica';
            case PaperType.HELLODOC_IZVID:
                return shortName ? 'HD izvid' : 'Halo Doktor izvid';
            case PaperType.MFMEDICAL_IZVID:
            case PaperType.MEDIFIT_MEDICAL_REPORT:
                return shortName ? 'MF Medical izvid' : 'Medifit Medical izvid';
            case PaperType.CONFIRM_VISIT:
                return this.getPaperTitle(type, 'Potrdilo o obisku');
            case PaperType.CONFIRM_RESERVATION:
                return this.getPaperTitle(type, 'Potrdilo o terminu');
            case PaperType.HELLODOC_ORDERFORM_LAB:
                return shortName ? 'LAB naročilnica' : 'Laboratorijska naročilnica';
            case PaperType.HELLODOC_ORDERFORM_DIAG:
                return shortName ? 'DIAG naročilnica' : 'Diagnostična naročilnica';
            case PaperType.NAPREDNI_IZVID:
                return this.getPaperTitle(type, 'Končni izvid');
            case PaperType.WORK_CERTIFICATE:
                return 'Spričevalo za delo';
            case PaperType.DRIVER_CERTIFICATE:
                return 'Spričevalo za voznike';
            case PaperType.DRIVER_CERTIFICATE_SPECIAL:
                return 'Spričevalo za voznike posebne zdravstvene komisije';
            case PaperType.WEAPON_CERTIFICATE:
                return 'Spričevalo za orožje';
            default:
                return type;
        }
    }

    private getPaperTitle(type: PaperType, title: string): string {
        return this.storage.getSettings()?.paperTypeSettings?.find(p => p.paperType === type)?.title || title;
    }
}
